import { Hint } from '@synoptic/ui-kit/hint/hint.tsx';
import { HelpCircleIcon } from '@synoptic/ui-kit/icons/react/help-circle.tsx';
import { grey } from '@synoptic/ui-kit/palette.ts';
import clsx from 'clsx';
import {
  reputationBetContainer,
  reputationBetHint,
  reputationBetLabel,
  reputationBetTextContainer,
  reputationBetTitle,
} from './reputation-bet.css.ts';
import { SemiCircleProgressBar } from '@/components/semi-circle-progress-bar/semi-circle-progress-bar.tsx';
import { convertReputationToLevel } from '@/utils/convert-reputation-to-level.ts';

export const ReputationBet = ({
  reputationBet,
  className,
  noHint = false,
}: {
  reputationBet: number;
  className?: string;
  noHint?: boolean;
}) => {
  return (
    <div className={clsx(reputationBetContainer, className)}>
      <SemiCircleProgressBar value={reputationBet} />
      <div className={reputationBetTextContainer}>
        <span className={reputationBetTitle}>
          {convertReputationToLevel(reputationBet)}
        </span>
        <span className={reputationBetLabel}>Reputation bet</span>
      </div>
      <div className={reputationBetHint}>
        {noHint ? null : (
          <Hint
            trigger={
              <HelpCircleIcon
                style={{ color: grey[600], width: 16, height: 16 }}
              />
            }
          >
            Reputation bet sets how much reputation user is willing to risk on
            this post.
          </Hint>
        )}
      </div>
    </div>
  );
};
